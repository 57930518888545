import React from 'react';
import { LiquidityTile } from "../../components/farming/LiquidityTile";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { AuthStore } from "../../stores";
import { EthWithdrawModal } from "../../modals/EthWithdrawModal";
import { LiquidityTransactionConfirmModal } from "../../modals/LiquidityTransactionConfirmModal";
import { pd, processRequestError } from "../../utils/utilities";
import { toast } from "react-toastify";
import $ from 'jquery';

interface LiquidityPoolTabProps{

}
interface LiquidityPoolTabState {
    withdrawId?: number;
    showWithdraw: boolean;
    loading: boolean;
    active: boolean;
}
const POOLS = {
    ufp: <><img src={require('../../images/monutes-img.svg')} alt=""/><p>UFP</p></>,
    eth: <><img src={require('../../images/ethereum.svg')} alt=""/><p>ETH</p></>,
}


@observer
export class LiquidityPoolTab extends React.Component<LiquidityPoolTabProps, LiquidityPoolTabState> {
    componentDidMount() {
        $(".monutes-title li").on("click", function () {
            $(".monutes-title li").removeClass("active")
            $(this).addClass("active")
            this.state.active = !this.state.active 
        })
    }
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    state: LiquidityPoolTabState = {
        showWithdraw: false,
        loading: false,
        active: true
    }
    onWithdraw = async () => {
        this.setState({ loading: true, showWithdraw: false });
        try {
            await this.authStore.ethWithdraw(this.state.withdrawId);
            toast.success('Eth pool was withdrawn');
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
	};
    onChange = async () => {
        this.setState({ active: !this.state.active });
	};
    render() {
        const { showWithdraw, loading, active } = this.state;

        return (
            <div className="tabs-item farming active">
                <div className="liquidity-pool active">
                    <div className="pool">
                        <LiquidityTile
                            icon={require('../../images/pool-after1.svg')}
                            title='UFP'
                            coinId='UFP'
                            infoWindow='Pool yield reward 3 - 7% per month in USDT'
                            usdt_balance={this.authStore.profile?.total_payed_unch}
                            total_payed_unch={this.authStore.profile?.total_payed_unch}
                            prematureWithdrawTime='2'
                            prematureWithdrawCommission='15'
                            periods={[
                                { months: '6', present: { title: 'Profit +3%', text: 'You will receive extra  bonus of 3% in UFP coins' } },
                                { months: '12', present: { title: 'Profit +7.5%', text: 'You will receive extra  bonus of 7.5% in UFP coins' } },
                                { months: '18', present: { title: 'Profit +12%', text: 'You will receive extra  bonus of 12% in UFP coins' } },
                                { months: '24', present: { title: 'Profit +18%', text: 'You will receive extra  bonus of 18% in UFP coins' } },
                            ]}
                            valute={require('../../images/balance-img1.svg')}
                            riskLine='0.2-0.65% per day - Risk 0.001%'
                            valute_name = 'Liquidity pool'
                            minimum_amount = {50000}
                            card_style = {{background: "linear-gradient(0.9deg, #361075 -2.65%, #552498 37.67%, #7036B6 80.56%, #7A3DC2 104.61%)"}}
                            info_text = 'info_text_UFP'
                            info_title = 'info_title_UFP'
                            />
                        <LiquidityTile
                            icon={require('../../images/pool-after2.svg')}
                            title='ETH'
                            coinId='ETH'
                            infoWindow='Ethereum pool yearly APY -   ~ 30%'
                            usdt_balance={this.authStore.profile?.ethereum_balance}
                            total_payed_unch={this.authStore.profile?.ethereum_balance}
                            // symbol='CRV'
                            valute={require('../../images/balance-img2.svg')}
                            prematureWithdrawTime='2.5'
                            prematureWithdrawCommission='20'
                            minimum_amount = {0.1}
                            riskLine='0.4-0.9% per day - Risk 10%'
                            valute_name = 'Ethereum pool'
                            card_style = {{background: "linear-gradient(180deg, #CD2576 0%, #8A164E 100%)"}}
                            info_text = 'info_text_ETH'
                            info_title = 'info_title_ETH'
                            showis_withdraw
                        />
                    </div>
                    <div className="monutes">
                        <div className="monutes-title">
                            <ul>
                                <li className="active"><a href="javascript:void(0)" onClick={() => this.onChange()}><i className="icon-deposits2"></i> Liquidity pool</a></li>
                                <li><a href="javascript:void(0)"  onClick={() => this.onChange()}><i className="icon-deposits2"></i> Ethereum pool</a></li>
                            </ul>
                        </div>
                        <div className="table">
                            <div className="monutes-table">
                                <ul>
                                    <li>#</li>
                                    <li>Coin</li>
                                    <li>Coins deposited</li>
                                    <li>Deposit term</li>
                                    <li>Profit</li>
                                    <li>Status</li>
                                    <li/>
                                </ul>
                            </div>
                            {active &&
                                <div className="monutes-content">
                                    {this.authStore.profile?.liquidity_log.map((l, i) => (
                                        <>{
                                            l.card_type == 'ufp' && (
                                                <div className="monutes-item" key={l.id}>
                                                    <div className="lvl">{i+1}</div>
                                                    <div className="coin"><i>Coin</i><p>{POOLS[l.card_type]}</p></div>
                                                    <div className="contributed"><i>Deposited</i>{l.amount}</div>
                                                    <div className="deposit"><i>Term</i>{l.length} days</div>
                                                    <div className="profit"><i>Profit</i>{l.total_earned} <strong>USDT</strong></div>
                                                    <div className="status"  style={{color: "#38C7D0;"}}><i className="icon-frozen"></i><span>
                                                    {
                                                        l.card_type == 'ufp' &&
                                                        <>
                                                        {l.is_finished==false &&(
                                                            <><i className="icon-check"></i><span>Active</span></>
                                                        )}
                                                        {l.is_finished==true &&(
                                                            <><i className="icon-top-arrow"></i><span>Finished</span></>
                                                        )}
                                                        </>
                                                    }
                                                    </span>
                                                    </div>                                        
                                                </div>

                                            )}
                                        </>
                                
                                ))}
                                <EthWithdrawModal show={showWithdraw} onHide={() => this.setState({ showWithdraw: false })} onConfirm={this.onWithdraw} />
                                </div>
                            }
                            {!active &&
                                <div className="monutes-content">
                                        {this.authStore.profile?.liquidity_log.map((l, i) => (
                                            <>{
                                                l.card_type == 'eth' && (
                                                    <div className="monutes-item" key={l.id}>
                                                        <div className="lvl">{i+1}</div>
                                                        <div className="coin"><i>Coin</i><p>{POOLS[l.card_type]}</p></div>
                                                        <div className="contributed"><i>Deposited</i>{l.amount}</div>
                                                        <div className="deposit"></div>
                                                        <div className="profit"><i>Profit</i>{l.total_earned} <strong>ETH</strong></div>
                                                        <div className="status"  style={{color: "#38C7D0;"}}><i className="icon-frozen"></i><span>
                                                        {
                                                            l.card_type == 'eth' &&
                                                            <div className="status" style={{color: "#35ba52;"}}>
                                                                {l.is_finished && <span style={{ color: 'red', fontStyle: 'italic' }}>Finished</span>}
                                                                {/* {!l.is_finished && (
                                                                    <a href='#' className={loading && 'disabled'} onClick={e => pd(e, this.setState({ showWithdraw: true, withdrawId: l.id }))}><i className="icon-top-arrow"/>Withdraw</a>
                                                                    )} */}
                                                            </div>
                                                        } 
                                                        </span>
                                                        </div>                                        
                                                    </div>

                                                )}
                                            </>
                                    
                                    ))}
                                    <EthWithdrawModal show={showWithdraw} onHide={() => this.setState({ showWithdraw: false })} onConfirm={this.onWithdraw} />
                                </div>
                            }
                        </div>
                    </div>
                </div> 
            </div>
        )
    }
}