import React from "react";
import { LiquidityTile } from "../../components/farming/LiquidityTile";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { AuthStore } from "../../stores";
import { EthWithdrawModal } from "../../modals/EthWithdrawModal";
import { LiquidityTransactionConfirmModal } from "../../modals/LiquidityTransactionConfirmModal";
import { pd, processRequestError } from "../../utils/utilities";
import { toast } from "react-toastify";
import $ from "jquery";
import GameRow from "../../components/GameRow";
import GameLevel from "../../components/GameLevel";

interface GamePoolTabProps {}
interface GamePoolTabPropsState {
  amount: number;
  loading: boolean;
}
function handleSubmit(event) {
  event.preventDefault();
}
type gameLevelType = {
  active: boolean;
  money_amount: string;
  tooltip_text: string;
  help_text: string;
};

@observer
export class GamePoolTab extends React.Component<
  GamePoolTabProps,
  GamePoolTabPropsState
> {
  componentDidMount() {}
  @resolve(AuthStore)
  protected declare readonly authStore: AuthStore;
  gameLevels: gameLevelType[] = [
    {
      active: true,
      money_amount: "Start",
      tooltip_text: "Level 1",
      help_text:
        "You have access to the 1lvl of referral commissions in the game pool.",
    },
    {
      active: false,
      money_amount: "$10 000",
      tooltip_text: "Level 2",
      help_text:
        "You have access to the 2lvl of referral commissions in the game pool.",
    },
    {
      active: false,
      money_amount: "$25 000",
      tooltip_text: "Level 3",
      help_text:
        "You have access to the 3lvl of referral commissions in the game pool.",
    },
    {
      active: false,
      money_amount: "$50 000",
      tooltip_text: "Level 4",
      help_text:
        "You have access to the 4lvl of referral commissions in the game pool.",
    },
    {
      active: false,
      money_amount: "$100 000",
      tooltip_text: "Level 5",
      help_text:
        "You have access to the 5lvl of referral commissions in the game pool.",
    },
  ];
  state: GamePoolTabPropsState = {
    amount: 0,
    loading: false,
  };
  
  onInvest = async () => {
    this.setState({ loading: true });

    try {
      if (this.state.amount < 100) {
        toast.error("Invested sum less than 100 usdt");
      } else {
        if (this.state.amount <= this.authStore.profile?.usdt_balance) {
          await this.authStore.gamePoolDeposit(
            this.state.amount,
            this.authStore.profile?.username
          );
          toast.success("Game pool had invested");
        } else {
          toast.error("Usdt balance is lower than invested sum");
        }
      }
    } catch (e) {
      processRequestError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  onWithdraw = async () => {
    this.setState({ loading: true });
    try {
      if (this.authStore.profile?.game_withdraw_balance > 0) {
        await this.authStore.gamePoolWithdraw(this.authStore.profile?.username);
        toast.success("Game pool balance had proceesed to  withdraw");
      }
    } catch (e) {
      processRequestError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  onRefWithdraw = async () => {
    this.setState({ loading: true });
    try {
      if (this.authStore.profile?.game_referral_withdraw_balance > 0) {
        await this.authStore.gamePoolReferralWithdraw(
          this.authStore.profile?.username
        );
        toast.success("Game referral balance had proceesed to  withdraw");
      }
    } catch (e) {
      processRequestError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { amount, loading } = this.state;

    return (
      <div className="tabs-item game active">
        <div className="switch">
          <span className="active">Balance</span>
          <span>Games</span>
        </div>
        <div className="balance">
          <div className="game-pool">
            <h3 style={{ position: "relative" }}>
              Game Pool Balance
              <span className="infoGame">
                <i className="icon-info">
                  <span className="info-window">
                    Is displayed here. Your profit from the game pool
                  </span>
                </i>
              </span>
            </h3>
            <div className="total">
              <span>Total balance</span>
              <h2>
                <img src={require("../../images/balance-img1.svg")} />
                {this.authStore.profile?.game_pool_balance.toFixed(6)}
              </h2>
              <span>USDT</span>
            </div>
          </div>
          <div className="inner">
            <div className="game-withdraw">
              <h3>
                Game Withdraw Balance
                <span className="infoGame">
                  <i className="icon-info">
                    <span className="info-window">
                      The GM balance receives 50% of the earned profit. This
                      indicator may change depending on the number of game deals
                      in the pool.
                    </span>
                  </i>
                </span>
              </h3>
              <div className="total">
                <span>Total balance</span>
                <h2>
                  <img src={require("../../images/balance-img1.svg")} />
                  {this.authStore.profile?.game_withdraw_balance}
                </h2>
                <span>USDT</span>
              </div>
              <a href="#" onClick={this.onWithdraw}>
                <i className="icon-top-arrow"></i>Withdraw
              </a>
            </div>
            <div className="referral-commission">
              <h3>
                Referral Comission
                <span className="infoGame">
                  <i className="icon-info">
                    <span className="info-window">
                      Displayed here referral commissions from your partners in
                      the game pool. Out of 100% of the received referral
                      commission - 75% of referrals goes to the referral balance
                      and 25% are available for withdrawal. The rest of the
                      commission goes to the balance for withdrawal every month
                      at 25%
                    </span>
                  </i>
                </span>
              </h3>
              <div className="total">
                <span>Total balance</span>
                <ul>
                  <li>
                    <h2>
                      <img src={require("../../images/balance-img1.svg")} />
                      {this.authStore.profile?.game_referral_balance.toFixed(2) || 0}
                    </h2>
                    <span className="description">USDT</span>
                  </li>
                  <li>
                    <h2>
                      <img src={require("../../images/balance-img1.svg")} />
                      {this.authStore.profile?.game_referral_withdraw_balance.toFixed(2)}
                    </h2>
                    <span className="description">USDT</span>
                    <a href="#" onClick={this.onRefWithdraw}>
                      <i className="icon-top-arrow"></i>Withdraw
                    </a>
                  </li>
                </ul>

                <span className="description">USDT</span>
              </div>
            </div>
          </div>
        </div>
        <div className="information">
          <i className="icon-check"></i>
          <p>
            You’ve made it to{" "}
            <span>Level {this.authStore.profile?.game_pool_level + 1}</span>!
            {/* Invest 25$ to keep growing. */}
          </p>
          <span className=" close icon-close"></span>
        </div>
        <ul className="level">
          {this.gameLevels.map((item: gameLevelType, index) => (
            <GameLevel
              active={
                true ? this.authStore.profile?.game_pool_level == index : false
              }
              money_amount={item.money_amount}
              tooltip_text={item.tooltip_text}
              help_text={item.help_text}
            />
          ))}
        </ul>
        <div className="pool">
          <div className="heading headingGame ">
            <h2>Game pool</h2>
            <span className="infoGameIco info">
              <i className="icon-info"></i>
              <span className="info-window">
              Pool income is credited daily to your Game pool Balance.
                    The income depends on the number of transactions and the funding of the pool.
                    <br/>
                    Every 30 days - 50% of the income received goes to the withdrawal balance from where you can withdraw to the general USDT balance.
                    The remaining 50% is accumulated with capitalization and will be available for withdrawal on the 11th and 12th months to the general balance.
                    Due to capitalization, we have a high-margin income from the game pool.
                    <br/>
                    The marginality and profit of transactions varies in different ranges and is distributed among all members of the pool.
                    <br/>
                    The profitability of the Game pool depends on the number of transactions in the pool itself in the range from 15 to 40% of the profit.
              </span>
            </span>
          </div>
          <div className="inner">
            <div className="item">
              <p>
                <span>The game pool</span> provides an opportunity to invest in
                the gaming industry on the blockchain and receive income from
                funding games.
              </p>
            </div>
            <div className="item amount">
              <h3>Amount</h3>
              <form onSubmit={handleSubmit}>
                <div className="inner">
                  <span
                    onClick={() =>
                      this.setState({
                        amount: this.authStore.profile?.usdt_balance,
                      })
                    }
                  >
                    Invest all
                  </span>
                  <input
                    type="number"
                    placeholder={this.authStore.profile?.usdt_balance.toString()}
                    min={100}
                    step={0.000001}
                    max={this.authStore.profile?.usdt_balance}
                    onChange={(e) =>
                      this.setState({ amount: parseFloat(e.target.value) })
                    }
                    disabled={loading}
                    value={amount}
                  />
                  <span>USDT</span>
                </div>
                <ul>
                  <li>
                    <span className="title">Annual Profit</span>
                    <h2 className="value">
                      <span>$</span>{ amount * 4.5 || 0 }
                    </h2>
                  </li>
                  <li>
                    <span className="title">Monthly Profit</span>
                    <h2 className="value">
                      <span>$</span>{ amount * 0.3 || 0 }
                    </h2>
                  </li>
                  <li>
                    <span className="title">GW income</span>
                    <h2 className="value">
                      50<span>%</span>
                    </h2>
                  </li>
                </ul>
                <button onClick={this.onInvest}>Invest</button>
              </form>
            </div>
            <div className="item indicators">
              <ul>
                <li>
                  <h2>
                    ~450% <span>Yearly APY</span>
                  </h2>
                </li>
                <li>
                  <h2>
                    ~30% <span>Monthly Income</span>
                  </h2>
                </li>
              </ul>
            </div>
          </div>
          <div className="decoration">
            <img src={require("../../images/game/pool-img-1.png")} alt="" />
            <img src={require("../../images/game/pool-img-2.png")} alt="" />
            <img src={require("../../images/game/pool-img-3.png")} alt="" />
            <img src={require("../../images/game/pool-img-4.svg")} alt="" />
            <img src={require("../../images/game/pool-img-5.png")} alt="" />
            <img src={require("../../images/game/pool-img-6.png")} alt="" />
            <img src={require("../../images/game/pool-img-7.svg")} alt="" />
            <img src={require("../../images/game/pool-img-8.svg")} alt="" />
            <img src={require("../../images/game/pool-img-9.png")} alt="" />
            <img src={require("../../images/game/pool-img-10.png")} alt="" />
            <img src={require("../../images/game/pool-img-11.png")} alt="" />
          </div>
        </div>
        <div className="investing">
          <div className="investing-title">
            <h4>Active pool</h4>
          </div>
          <div className="investing-content">
            <div className="title">
              <ul>
                <li>#</li>
                <li>Balance</li>
                <li>Period</li>
                <li>Profit %</li>
                <li>Profit $</li>
                <li></li>
              </ul>
            </div>
            <div className="items">
              {this.authStore.profile?.game_pool_deposits.map((item, index) => (
                <GameRow
                  index={index + 1}
                  amount={item.amount}
                  coin={"USDT"}
                  length={item.days_passed}
                  profit_percent={item.profit_percent}
                  profit={item.profit}
                  pk={item.pk}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
