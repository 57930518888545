import React from "react";
import { RebalancingStaking } from "./RebalancingStaking";
import { RebalancingHistoryInvesting } from "./RebalancingHistoryInvesting";

export const Rebalancing = () => {
  return (
    <div className="rebalancing_data">
      <div className="rebalancing">
        <div className="rebalancing-content">
          <RebalancingStaking />
        </div>
        <RebalancingHistoryInvesting />
      </div>
    </div>
  );
};
