import React from 'react';
import { Modal } from "../components/Modal";
import { Modals, ModalStore } from "../stores/ModalStore";
import { observer } from "mobx-react";
import { pd } from "../utils/utilities";
import { resolve } from "inversify-react";
import { AuthStore, WalletStore } from "../stores";
import { toast } from "react-toastify";
import { processRequestError } from "../utils/utilities";

interface IEthWithdrawModalAddressProps {
    show: boolean;
	onHide: () => any;
	onConfirm: () => any;
    minimum_amount: number;
}

interface IEthWithdrawModalAddressState {
    amount: string;
    address: string;
    loading: boolean;
}

@observer
export class EthWithdrawModalAddress extends React.Component<IEthWithdrawModalAddressProps, IEthWithdrawModalAddressState> {
    @resolve(AuthStore)
    declare private readonly authStore: AuthStore;
    @resolve(WalletStore)
    declare private readonly walletStore: WalletStore;
    @resolve(ModalStore)
    declare private readonly modalStore: ModalStore;

    state: IEthWithdrawModalAddressState = {
        amount: '',
        address: '',
        loading: false,
    }

    onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        this.setState({ loading: true });
        if (Math.max(0, (parseFloat(this.state.amount) || 0)) > this.authStore.profile?.ethereum_balance){
            toast.error("Not enough ETH")
        } else {
            try {
                await this.authStore.ethAddressWithdraw(
                    Math.max(0, (parseFloat(this.state.amount) || 0)).toString(), 
                    this.state.address
                );
                this.modalStore.hideModals();
                toast.success('The withdrawal request was created')
            } catch (e) {
                processRequestError(e);
            } finally {
                this.setState({ loading: false });
            }
        }
    }

    render() {
        const { show, onHide, onConfirm, minimum_amount } = this.props;
        const { amount, address, loading } = this.state;
        const commission = this.authStore.profile?.settings.eth_comission_amount;
        const willGet = Math.max(0, (parseFloat(amount) || 0) - commission);
        return (
            <Modal modalClassName='popup-withdraw' show={show} onHide={onHide}>
                <div className="title">
                    <h4>Withdraw</h4>
                </div>
                <div className="withdraw-content">
                    <p>Enter the number of eth and specify the wallet address for withdrawal</p>
                    <p>Available for withdraw: {this.authStore.profile?.ethereum_balance.toFixed(6)}</p>
                    <form onSubmit={this.onSubmit}>
                        <div className="inputs">
                            <div className="input">
                                <div>
                                    <label>Amount of ETH</label>
                                    <input
                                        type="number"
                                        placeholder="0.00"
                                        min={minimum_amount}
                                        max={this.authStore.profile?.ethereum_balance}
                                        step={.000001}
                                        value={amount}
                                        onChange={e => this.setState({ amount: e.target.value })}
                                    />
                                </div>
                                <strong>ETH</strong>
                            </div>
                            <p>Commission: <strong>{commission}</strong> ETH</p>
                            <p>Will get: <strong>{willGet.toFixed(2)}</strong> ETH</p> 
                            
                            <div className="input">
                                <div>
                                    <label>Wallet address</label>
                                    <input
                                        type="text"
                                        value={address}
                                        onChange={e => this.setState({ address: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <button type='submit' onClick={e => this.onSubmit(e)} disabled={!address || !parseFloat(amount) || loading}><i className="icon-top-arrow"/><span>Withdraw</span></button>
                    </form>
                </div>
            </Modal>
        )
    }
}