import React from "react";
import { Modal } from "../components/Modal";
import { AuthStore, WalletStore } from "../stores";
import { Modals, ModalStore } from "../stores/ModalStore";
import { toast } from "react-toastify";
import classNames from "classnames";
import { resolve } from "inversify-react";
import { pd, processRequestError, toBNJS, toFixed, toFixedNumber } from "../utils/utilities";
import { observer } from "mobx-react";
import BN from 'bignumber.js';
import detectEthereumProvider from '@metamask/detect-provider'
import Web3 from 'web3';


interface ILiquidityTransactionConfirmModalProps {
	show: boolean;
	amount: any;
    minimum_amount: number;
    periods: { months: string; present?: { title: string; text: string } }[];
	onHide: () => any;
	onConfirm: () => any;
    coin: string;
}

interface ILiquidityTransactionConfirmModalState {
    amount: number;
    coin: string; 
    length: number;
    loading: boolean;
}
function UsdtCompareException(message: any) {
    this.message = message;
    this.name = "Exception to coompare balance amount";
}
export class LiquidityTransactionConfirmModal extends React.Component<
	ILiquidityTransactionConfirmModalProps,
	ILiquidityTransactionConfirmModalState
> {
    @resolve(AuthStore)
    declare private readonly authStore: AuthStore;
    @resolve(ModalStore)
    declare private readonly modalStore: ModalStore;
    @resolve(WalletStore)
    declare private readonly walletStore: WalletStore;

    state: ILiquidityTransactionConfirmModalState = {
        amount: 0,
        coin: 'ufp', 
        length: 0,
        loading: false,
    }
    onDeposit= async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            console.log('called')
            if (this.props.coin =='ufp'){
                if(this.state.amount < this.props.minimum_amount) 
                    throw new UsdtCompareException("Usdt amount must be bigger 1");
                const unichainContract = this.walletStore.unichainContract;
                console.log(toFixed(toBNJS(this.state.amount).times(toBNJS(10).pow(await unichainContract.decimals())).integerValue().toString() ))
                const tx = await unichainContract.transfer(
                    this.authStore.profile?.settings.liqudity_deposit_address,
                    toFixed(toBNJS(this.state.amount).times(toBNJS(10).pow(await unichainContract.decimals())).integerValue().toString() )
                );
                toast.success('liquidity will be created once the transaction is confirmed');
                await tx.wait();
                toast.success('liquidity transaction is confirmed, please wait a few seconds');
                e.preventDefault();
                this.setState({ loading: true });
                try {
                    console.log(this.walletStore.unichainBalance)
                    console.log(toBNJS(this.state.amount))
                    console.warn(this.state)
                    await this.authStore.liquidityDeposit(
                        this.state.amount
                        , this.authStore?.profile.username, this.props.coin, this.state.length * 30 );
                    this.modalStore.hideModals();
                    toast.success('The liquidity deposit request was created')
                } catch (e) {
                    console.log(e)
                    processRequestError(e);
                } finally {
                    this.setState({ loading: false });
                }
            }
            else {
                const paymentAddress = this.authStore?.profile?.settings?.liqudity_eth_address;
                this.setState({length: 10000});
                console.log(this.state.amount)
                console.log(this.state.amount.toString())
                const tx = window.web3.eth.sendTransaction({
                    to: paymentAddress,
                    from: this.authStore?.profile?.username,
                    value: window.web3.utils.toWei(this.state.amount.toString(), 'ether')
                  }, (err, transactionId) => {
                    if  (err) console.log('Payment failed', err)
                    else {
                        console.log('Payment successful', transactionId)
                        toast.success('liquidity transaction is confirmed, please wait a few seconds');
                        e.preventDefault();
                        this.setState({ loading: true });
                        try {
                            console.log(this.walletStore.unichainBalance)
                            console.log(toBNJS(this.state.amount))
                            console.warn(this.state)
                            this.authStore.liquidityDeposit(
                                this.state.amount
                                , this.authStore?.profile?.username, this.props.coin, this.state.length * 30 );
                            this.modalStore.hideModals();
                            toast.success('The liquidity deposit request was created')
                        } catch (e) {
                            console.log(e)
                            processRequestError(e);
                        } finally {
                            this.setState({ loading: false });
                        }
                    }
                })
               
            }
           
        } catch (e) {
            console.log(e);
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }
    componentDidMount(){
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
            window.ethereum.enable();
          } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider)
          }
    }

	render() {
        const {length} = this.state;
		const { show, amount, onHide, onConfirm, periods, minimum_amount, coin } = this.props;
		return (
			<Modal
				modalClassName="popup-replenishment"
				show={show}
				onHide={onHide}
			>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/web3/1.3.6/web3.min.js"></script>
                {this.props.coin =='ufp' &&
                    <>
					<div className="title">
						<h4>
							<i className="icon-top-arrow"></i>Refill
						</h4>
					</div>
					<div className="balance">
						<div>
							<span>Balance</span>
							<strong>{amount} {this.props.coin.toUpperCase()}</strong>
						</div>
						<div>
							<span>Not enough coins?</span>
							<a href="javascript:void(0)"  onClick={e => pd(e, this.modalStore.showModal(Modals.ExchangeDeposit))}>
								<i className="icon-top-arrow"></i>Buy
							</a>
						</div>
					</div>
                    </>
                }
					<div className="replenishment-content">
						<form action="">
							<div className="inputs">
								<div>
                                {this.props.coin =='ufp' &&
									<label>Coins amount</label>
                                }   
                                {this.props.coin =='eth' &&
									<label>Ethereum amount</label>
                                }   
									<div className="input">
										<div>
											<input
												type="text"
												placeholder={minimum_amount.toString()}
                                                min={minimum_amount}
                                                onChange={e => this.setState({ amount: parseFloat(e.target.value) })}
											/>
										</div>
										<strong>{this.props.coin.toUpperCase()}</strong>
									</div>
                                    {
                                        this.props.coin == 'ufp' &&
                                        <>
                                        <label>
                                            Select the period for sending coins
                                        </label>
                                        <div className="input">
                                            <div>
                                                <input
                                                    type='text'
                                                    placeholder="30"
                                                    value={length}
                                                    disabled
                                                    onChange={e => this.setState({ length: parseInt(e.target.value,10)})}
                                                />
                                            </div>
                                            <strong>Months</strong>
                                        </div></>
                                    }
								</div>
                                {
                                    this.props.coin == 'ufp' &&
                                    <ul className="days">
                                        {periods?.map(p => (
                                            <li className={classNames({ present: !!p.present, active: length ==parseInt( p.months,10) })} onClick={() => this.setState({ length: parseInt(p.months,10) })}>
                                                <span>
                                                    {p.months}{' '}<span>months</span>
                                                </span>
                                                {p.present && (
                                                    <>
                                                        <i className="icon-present"/>
                                                        <div className="present-info">
                                                            <strong>{p.present.title}</strong>
                                                            <p>{p.present.text}</p>
                                                        </div>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                }
							</div>
							<button onClick={(e) => pd(e, this.onDeposit(e))}>
								<i className="icon-plus"></i>
								<span>Refill</span>
							</button>
						</form>
					</div>
			</Modal>
		);
	}
}
