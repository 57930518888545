import React, { Component } from "react";
import { RebalanceInvestingItem } from "./RebalanceInvestingItem";
import { AuthStore } from "../../../../stores";
import { resolve } from "inversify-react";
import { processRequestError } from "../../../../utils/utilities";
import { api } from "../../../../utils/api";
import { observer } from "mobx-react";

interface IRebalance {
  pk: number;
  amount: string;
  unfrozen_sum: string;
  deposit_profit: string;
  available_to_withdraw: string;
  user: number;
  passed_time: number;
  withdraw_commission: string;
  is_rebalance_available: boolean;
  rebalance_comission: number;
}

@observer
export class RebalancingHistoryInvesting extends Component {
  @resolve(AuthStore)
  protected declare readonly authStore: AuthStore;
  state = {
    rebalancing: [] as IRebalance[],
  };

  componentDidMount() {
    this.fetchRebalancingData();
  }

  fetchRebalancingData = async () => {
    try {
      if (this.authStore.profile?.username) {
        const response = await api.getRebalanceRequests(
          this.authStore.profile.username
        );
        if (response.data) {
          this.setState({ rebalancing: response?.data });
        }
      }
    } catch (error) {
      processRequestError(error);
    }
  };

  render() {
    const { rebalancing } = this.state;

    return (
      <div className="rebalancing_history-investing">
        <div className="investing-title">
          <h4>Active rebalance</h4>
        </div>
        {rebalancing?.length > 0 && (
          <>
            <div className="rebalancing_investing_items">
              {rebalancing.map((item) => (
                <RebalanceInvestingItem key={item.pk} item={item} />
              ))}
            </div>
          </>
        )}
      </div>
    );
  }
}
