import React from "react";
import { FarmingTile } from "../../components/farming/FarmingTile";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { AuthStore } from "../../stores";
import { FarmingWithdrawConfirmModal } from "../../modals/FarmingWithdrawConfirmModal";
import { pd, processRequestError } from "../../utils/utilities";
import { Rebalancing } from "../../components/farming/Rebalancing";
import { toast } from "react-toastify";

interface IFarmingTabProps {}

interface IFarmingTabState {
  withdrawId?: number;
  showWithdraw: boolean;
  loading: boolean;
  showFarming: boolean;
  showRebalancing: boolean;
}

const COINS = {
  usdt: (
    <>
      <img src={require("../../images/usdt-img.svg")} alt="" /> <i>USDT</i>
    </>
  ),
  curve: (
    <>
      <img src={require("../../images/curve-img.svg")} alt="" /> <i>СRV</i>
    </>
  ),
  sushi: (
    <>
      <img src={require("../../images/sushi-img.svg")} alt="" /> <i>SUSHI</i>
    </>
  ),
  farm: (
    <>
      <img src={require("../../images/HarvestFinance_32.webp")} alt="" />{" "}
      <i>FARM</i>
    </>
  ),
};

@observer
export class FarmingTab extends React.Component<
  IFarmingTabProps,
  IFarmingTabState
> {
  @resolve(AuthStore)
  protected declare readonly authStore: AuthStore;

  state: IFarmingTabState = {
    showWithdraw: false,
    loading: false,
    showFarming: true,
    showRebalancing: false,
  };

  onWithdraw = async () => {
    this.setState({ loading: true, showWithdraw: false });
    try {
      await this.authStore.farmingWithdraw(this.state.withdrawId);
      toast.success("Farming deposit was withdrawn");
    } catch (e) {
      processRequestError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  onFarmingClick = () => {
    this.setState({ showFarming: true, showRebalancing: false });
  };

  onRebalancingClick = () => {
    this.setState({ showFarming: false, showRebalancing: true });
  };

  render() {
    const { showWithdraw, loading, showFarming, showRebalancing } = this.state;

    return (
      <div className="tabs-item farming active">
        <div className="farming-title">
          <ul>
            <li
              data-tabs="farming_data"
              className={showFarming ? "active" : ""}
            >
              <a onClick={this.onFarmingClick}>
                Farming
              </a>
            </li>
            <li
              data-tabs="rebalancing_data"
              className={showRebalancing ? "active" : ""}
            >
              <a onClick={this.onRebalancingClick}>
                Rebalancing
              </a>
            </li>
          </ul>
        </div>
        {showFarming && (
          <div className="farming_data">
            <div className="finance">
              <div className="finance-content">
                <FarmingTile
                  icon={require("../../images/usdt-img2.svg")}
                  title="USDT"
                  coinId="usdt"
                  // symbol='USDT'
                  prematureWithdrawTime="2"
                  prematureWithdrawCommission="15"
                  periods={[
                    { days: "60" },
                    { days: "90" },
                    { days: "120" },
                    {
                      days: "180",
                      present: {
                        title: "Profit +10%",
                        text: "When farming for 150 days or more, the earnings will be 10% more",
                      },
                    },
                    { days: "250" },
                    {
                      days: "360",
                      present: {
                        title: "Profit 15% - 30% + Token X Bonus",
                        text: "When farming from 300 days or more, the earnings will be 15% - 30% more",
                      },
                    },
                    { days: "730" },
                    { days: "1095" },
                  ]}
                  riskLine="~ 0.05-0.3% per day - Risk 0.001%"
                  links={[
                    "https://etherscan.io/address/0x3416cf6c708da44db2624d63ea0aaef7113527c6",
                    "https://etherscan.io/address/0x4e68ccd3e89f51c3074ca5072bbac773960dfa36",
                    "https://etherscan.io/address/0x11b815efb8f581194ae79006d24e0d814b7697f6",
                  ]}
                />
                <FarmingTile
                  icon={require("../../images/curve-img.svg")}
                  title="Curve DAO Token"
                  coinId="curve"
                  // symbol='CRV'
                  prematureWithdrawTime="2.5"
                  prematureWithdrawCommission="20"
                  periods={[
                    { days: "75" },
                    { days: "90" },
                    { days: "120" },
                    { days: "175" },
                    {
                      days: "200",
                      present: {
                        title: "Profit + 15%",
                        text: "When farming for 200 days or more, the earnings will be 15% more",
                      },
                    },
                    {
                      days: "350",
                      present: {
                        title: "Profit + 25% + Token X Bonus",
                        text: "When farming from 350 days or more, the earnings will be 25% more + Token X Bonus",
                      },
                    },
                    { days: "730" },
                    { days: "1095" },
                  ]}
                  riskLine="~ 0.4-0.9% per day - Risk 10%"
                  links={[
                    "https://etherscan.io/address/0x07b1c12be0d62fe548a2b4b025ab7a5ca8def21e",
                    "https://etherscan.io/address/0x4c83a7f819a5c37d64b4c5a2f8238ea082fa1f4e",
                  ]}
                />
                <FarmingTile
                  icon={require("../../images/sushi-img.svg")}
                  title="SUSHI"
                  coinId="sushi"
                  // symbol='Sushi'
                  prematureWithdrawTime="2"
                  prematureWithdrawCommission="30"
                  periods={[
                    { days: "60" },
                    { days: "90" },
                    { days: "120" },
                    {
                      days: "125",
                      present: {
                        title: "Profit +25% profit + Token X Bonus",
                        text: "When farming from 125 days or more, the earnings will be 25% more + Token X Bonus",
                      },
                    },
                    { days: "200" },
                    {
                      days: "250",
                      present: {
                        title: "Profit +50% profit + Token X Bonus",
                        text: "When farming for 250 days or more, the earnings will be 50% more + Token X Bonus",
                      },
                    },
                    { days: "730" },
                    { days: "1095" },
                  ]}
                  riskLine="~ 0.5-1% per day - Risk 30%"
                  links={[
                    "https://etherscan.io/address/0x73a6a761fe483ba19debb8f56ac5bbf14c0cdad1",
                    "https://analytics.sushi.com/pools/12",
                  ]}
                />
                <FarmingTile
                  icon={require("../../images/HarvestFinance_32.webp")}
                  title="FARM"
                  coinId="farm"
                  // symbol='Farm'
                  prematureWithdrawTime="2.5"
                  prematureWithdrawCommission="40"
                  periods={[
                    { days: "75" },
                    { days: "90" },
                    {
                      days: "120",
                      present: {
                        title: "Profit +40%",
                        text: "When farming from 120 days or more, the earnings will be 40% more",
                      },
                    },
                    { days: "170" },
                    {
                      days: "200",
                      present: {
                        title: "Profit + 50% + Token X Bonus",
                        text: "When farming for 200 days or more, the earnings will be 50% more + Token X Bonus",
                      },
                    },
                    { days: "350" },
                    { days: "730" },
                    { days: "1095" },
                  ]}
                  riskLine="~ 0.5-1.3% per day - Risk 45%"
                  disabled
                />
              </div>
              <div className="history-investing">
                <div className="investing-title">
                  <i className="icon-deposits2" />
                  <h4>Active Farm</h4>
                </div>
                <div className="history-content">
                  <div className="history-title">
                    <ul>
                      <li>#</li>
                      <li>Coin</li>
                      <li>Total balance</li>
                      <li>Period</li>
                      <li>Per day</li>
                      <li>Profit</li>
                      <li />
                    </ul>
                  </div>
                  <div className="history-items">
                    {this.authStore.profile?.farming_log.map((l, i) => (
                      <div className="history-item" key={l.pk}>
                        <div className="data">{i + 1}</div>
                        <div className="coins">{COINS[l.coin]}</div>
                        <div className="balance">{l.amount.toFixed(2)}</div>
                        <div className="period">
                          <strong>
                            {l.days_passed}
                            <span>
                              \{l.length} days
                              <i>
                                {l.extra_percent && (
                                  <>
                                    +{l.extra_percent * 100}%<br />
                                  </>
                                )}
                                {l.extra_tokenx > 0 && "+Token X Bonus"}
                              </i>
                            </span>
                          </strong>
                        </div>
                        <div className="per-day">
                          {(l.daily_percent * 100).toFixed(4)}%
                        </div>
                        <div className="profit">
                          {(l.profit_percent * 100).toFixed(4)}%
                        </div>
                        <div className="withdraw">
                          {l.is_destroyed && (
                            <span style={{ color: "red", fontStyle: "italic" }}>
                              Illiquid
                            </span>
                          )}
                          {!l.is_destroyed && (
                            <a
                              href="#"
                              className={loading && "disabled"}
                              onClick={(e) =>
                                pd(
                                  e,
                                  this.setState({
                                    showWithdraw: true,
                                    withdrawId: l.pk,
                                  })
                                )
                              }
                            >
                              <i className="icon-top-arrow" />
                              Withdraw
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <FarmingWithdrawConfirmModal
              show={showWithdraw}
              onHide={() => this.setState({ showWithdraw: false })}
              onConfirm={this.onWithdraw}
            />
          </div>
        )}
        {showRebalancing && <Rebalancing />}
      </div>
    );
  }
}
