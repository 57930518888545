import React, { Component } from "react";
import { RebalancingWithdrawModal } from "../../../../../modals/RebalancingWithdrawModal";
import { FarmingWithdrawConfirmModal } from "../../../../../modals/FarmingWithdrawConfirmModal";
import { toast } from "react-toastify";
import { AuthStore } from "../../../../../stores";
import { resolve } from "inversify-react";
import { api } from "../../../../../utils/api";
import { processRequestError } from "../../../../../utils/utilities";
import { observer } from "mobx-react";

interface IRebalanceItem {
  pk: number;
  amount: string;
  unfrozen_sum: string;
  deposit_profit: string;
  available_to_withdraw: string;
  user: number;
  passed_time: number;
  withdraw_commission: string;
  is_rebalance_available: boolean;
  rebalance_comission: number;
}

interface IRebalanceItemState {
  showWithdrawModal: boolean;
  showConfirmWithdrawModal: boolean;
  withdrawType: string;
  loading: boolean;
}

type Props = {
  item: IRebalanceItem;
};

@observer
export class RebalanceInvestingItem extends Component<
  Props,
  IRebalanceItemState
> {
  @resolve(AuthStore)
  protected declare readonly authStore: AuthStore;
  state: IRebalanceItemState = {
    showWithdrawModal: false,
    showConfirmWithdrawModal: false,
    withdrawType: "",
    loading: false,
  };

  onShowWithdrawModal = () => {
    if (this.state.loading) return;
    // const { passed_time } = this.props.item;
    // if (passed_time >= 365) {
      this.setState({ showWithdrawModal: true });
    // } else {
    //   toast.error("Withdrawal available after 365 days");
    // }
  };

  onWithdrawBtnClick = (withdrawType: string) => {
    this.setState({
      withdrawType,
      showWithdrawModal: false,
      showConfirmWithdrawModal: true,
    });
  };

  onHideConfirmWithdrawModal = () => {
    this.setState({ showConfirmWithdrawModal: false });
  };

  onConfirmWithdrawBtnClick = () => {
    const { withdrawType } = this.state;
    this.fetchWithdrawRebalance(withdrawType, this.props.item.pk);
  };

  fetchWithdrawRebalance = async (type: string, requestNumber: number) => {
    this.setState({ loading: true });

    try {
      if (this.authStore.profile?.username) {
        await api.withdrawRebalance(
          type,
          this.authStore.profile.username,
          requestNumber
        );
        toast.success(`${type} successfully withdrawn`);
      }
    } catch (error) {
      processRequestError(error);
    } finally {
      this.setState({ showConfirmWithdrawModal: false });
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      amount,
      unfrozen_sum,
      available_to_withdraw,
      deposit_profit,
      withdraw_commission,
      is_rebalance_available,
      passed_time,
    } = this.props.item || {};
    const { showWithdrawModal, showConfirmWithdrawModal } = this.state;

    return (
      <>
        <div className="rebalancing_investing">
          <div className="title">
            <img src={require("../../../../../images/usdt-img2.svg")} alt="" />
            <h4>USDT</h4>
          </div>
          <form>
            <div className="item">
              <div className="input">
                <strong>Rebalance invested:</strong>
                <input
                  type="text"
                  placeholder={amount ? amount : ""}
                  disabled
                />
                <p>
                <span className="info">
                  <i className="icon-info" />
                  {/* <i className="icon-info"/> */}
                  <span className="info-window">The invested rebalance amount is available for withdrawal after 12 months</span>
                </span>
              </p>
              </div>
            </div>
            <div className="item">
              <div className="input">
                <strong>Unfreeze balance:</strong>
                <input
                  type="text"
                  placeholder={unfrozen_sum ? unfrozen_sum : ""}
                  disabled
                />
                <p>
                <span className="info">

                  <i className="icon-info" />
                  {/* <i className="icon-info"/> */}
                  <span className="info-window">The calculation of the frozen balance will be calculated automatically
                    according to the data from the liquidity pool </span>
                </span>
              </p>
              </div>
            </div>
            <div className="item">
              <div className="input">
                <strong>Accumulated profit:</strong>
                <input
                  type="text"
                  placeholder={
                    available_to_withdraw && deposit_profit
                      ? `${Number(available_to_withdraw).toFixed(3)}/${Number(deposit_profit).toFixed(3)}`
                      : ""
                  }
                  disabled
                />
                <p>
                <span className="info">
                  USDT
                  <i className="icon-info" />
                  {/* <i className="icon-info"/> */}
                  <span className="info-window">Profit is accured every 1st day </span>
                </span>
              </p>
              </div>
            </div>
          </form>
          <div className="balance">
            <span>
              Duration of working liquidity pool: <i>{passed_time}</i>
            </span>
          </div>
          <a
            className="farm withdrawal_js"
            onClick={() => {
              this.onShowWithdrawModal();
            }}
          >
            <i className="icon-top-arrow"></i>Withdraw
          </a>
        </div>
        <RebalancingWithdrawModal
          show={showWithdrawModal}
          onHide={() => this.setState({ showWithdrawModal: false })}
          onWithdraw={this.onWithdrawBtnClick}
          amount={amount}
          withdrawCommission={withdraw_commission}
          availableToWithdraw={available_to_withdraw}
          isRebalanceAvailable={is_rebalance_available}
        />
        <FarmingWithdrawConfirmModal
          show={showConfirmWithdrawModal}
          onHide={this.onHideConfirmWithdrawModal}
          onConfirm={this.onConfirmWithdrawBtnClick}
        />
      </>
    );
  }
}
