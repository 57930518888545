import React, { Component } from "react";
import { FarmingTransactionConfirmModal } from "../../../../modals/FarmingTransactionConfirmModal";
import { AuthStore } from "../../../../stores";
import { resolve } from "inversify-react";
import { processRequestError } from "../../../../utils/utilities";
import { api } from "../../../../utils/api";
import { observer } from "mobx-react";
import { toast } from "react-toastify";

@observer
export class RebalancingStaking extends Component {
  @resolve(AuthStore)
  protected declare readonly authStore: AuthStore;
  state = {
    showConfirmModal: false,
    amount: null,
    loading: false,
  };

  fetchCreateRebalance = async (amount: string) => {
    this.setState({ loading: true });

    try {
      if (this.authStore.profile?.username) {
        await api.createRebalanceRequest(
          amount,
          this.authStore.profile.username
        );
        toast.success("Rebalance request successfully created");
      }
    } catch (error) {
      processRequestError(error);
    } finally {
      this.onHideConfirmModal();
      this.setState({ loading: false });
    }
  };

  onShowConfirmModal = () => {
    this.setState({ showConfirmModal: true });
  };

  onHideConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  };

  handleLiquidityPoolBtnClick = () => {
    const { amount } = this.state;

    if (amount >= 3000 && amount <= this.authStore.profile?.usdt_balance) {
      this.onShowConfirmModal();
    } else if (amount < 3000) {
      toast.error("Amount must be equal to 3000 or more");
    } else if (
      amount > this.authStore.profile?.usdt_balance &&
      this.authStore.profile?.usdt_balance > 3000
    ) {
      toast.error(
        `Amount must be equal to ${this.authStore.profile?.usdt_balance} or less`
      );
    } else {
      toast.error("Current balance is less than 3000");
    }
  };

  handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ amount: event.target.value });
  };

  handleFarmAll = () => {
    this.setState({ amount: this.authStore.profile?.usdt_balance });
  };

  render() {
    const { showConfirmModal, amount, loading } = this.state;

    return (
      <>
        <div className="rebalancing_staking">
          <div className="staking-title">
            <img src={require("../../../../images/usdt-img2.svg")} alt="" />
            <h4>USDT</h4>
          </div>
          <div className="staking-content">
            <form onSubmit={(event) => event.preventDefault()}>
              <div className="item">
                <label>Quantity of coins</label>
                <div className="input">
                  <button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      background: "none",
                      color: "#6C34B1",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "17px",
                      position: "relative",
                      zIndex: 3,
                    }}
                    onClick={this.handleFarmAll}
                  >
                    <strong>Farm all</strong>
                  </button>
                  <input
                    type="number"
                    min="3000"
                    max={this.authStore.profile?.usdt_balance}
                    placeholder="0.00"
                    onChange={this.handleAmountChange}
                    disabled={loading}
                    value={amount}
                  />
                  <span>USDT</span>
                </div>
              </div>
            </form>
            <div className="balance">
              <p>Limit: 100 000 USDT</p>
              <p>
                Your unfreeze balance will be displayed some time after the
                request is created
              </p>
              <p>You need to deposit 265% of the frozen deposit amount</p>
            </div>
            <a
              className="farm transaction_js"
              onClick={this.handleLiquidityPoolBtnClick}
            >
              <i className="icon-plus"></i>Liquidity-pool rebalance
            </a>
          </div>
        </div>
        <FarmingTransactionConfirmModal
          show={showConfirmModal}
          onHide={this.onHideConfirmModal}
          onConfirm={() => this.fetchCreateRebalance(amount)}
        />
      </>
    );
  }
}
