import React, { useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "../components/Modal";

interface IRebalancingWithdrawConfirmModalProps {
  show: boolean;
  onHide: () => any;
  onWithdraw: (type: string) => any;
  amount: string;
  withdrawCommission: string;
  availableToWithdraw: string;
  isRebalanceAvailable: boolean;
}

export const RebalancingWithdrawModal: React.FC<
  IRebalancingWithdrawConfirmModalProps
> = ({
  show,
  onHide,
  onWithdraw,
  amount,
  withdrawCommission,
  availableToWithdraw,
  isRebalanceAvailable,
}) => {
  const [showProfit, setShowProfit] = useState(true);

  return (
    <Modal modalClassName="popup-withdrawal" show={show} onHide={onHide}>
      <div className="title">
        <h4>
          Withdrawal <br />
          Minimum withdraw amount: 200
        </h4>
      </div>
      <div className="withdraw-content">
        <ul>
          <li
            data-tabs="profit"
            className={showProfit ? "active" : ""}
            onClick={() => setShowProfit(true)}
          >
            Profit
          </li>
          <li
            data-tabs="rebalance"
            className={!showProfit ? "active" : ""}
            onClick={() => {
              // if (isRebalanceAvailable) {
                setShowProfit(false);
              // }
            }}
          >
            Rebalance
          </li>
        </ul>
        {showProfit && (
          <div className="popup_tabs profit active">
            <div className="commission">
              <div>
                <span>Amount of coins</span>
                <strong>{availableToWithdraw}</strong>
              </div>
              <i> USDT</i>
            </div>
            <p>Comission: {withdrawCommission}$</p>
            <p>{`Will get: ${Number(availableToWithdraw) - Number(withdrawCommission)}`}</p>
          </div>
        )}
        {!showProfit && (
          <div className="popup_tabs rebalance active">
            <div className="commission">
              <div>
                <span>Amount of coins</span>
                <strong>{amount}</strong>
              </div>
              <i> USDT</i>
            </div>
            <p>Comission: {withdrawCommission}$</p>
            <p>{`Will get: ${Number(amount) - Number(withdrawCommission)}`}</p>
          </div>
        )}
        <a
          className="transaction_js"

          onClick={() => {
              if (showProfit && Number(availableToWithdraw) < 200) {
                  toast.error("For withdraw need 200 or more coins");
              }
              else if (!showProfit && !isRebalanceAvailable) {
                  toast.error("For rebalance 365 days must pass");
              } else {
                  onWithdraw(
                      isRebalanceAvailable && !showProfit ? "rebalance" : "profit"
                  )
              }
          }
          }
        >
          Withdraw
        </a>
      </div>
    </Modal>
  );
};
