import React from "react";

type gameRowType = {
  index: number;
  coin: string;
  length: number;
  profit_percent: number;
  profit: number;
  amount: number;
  pk: number;
};

function GameRow(props: gameRowType) {
  return (
    <div className="item">
      <div className="data">{props.index}</div>
      <div className="coins">
        {props.amount}&nbsp;<img src={require("../images/usdt-img.svg")} alt="" />{" "}
        <i>{props.coin}</i>
      </div>
      <div className="period">{props.length} days</div>
      <div className="profit-procent">
        <span>Profit %</span>
        {props.profit_percent.toFixed(3)}%
      </div>
      <div className="profit">
        <span>Profit $</span>
        {props.profit} USDT
      </div>
    </div>
  );
}
export default GameRow;
