import React from 'react';
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { AuthStore, ModalStore, WalletStore } from "../../stores";
import { BalancesRow } from "../../components/dashboard/BalancesRow";
import { XFarmExchange } from "../../components/dashboard/XFarmExchange";
import { Referral } from "../../components/dashboard/Referral";
import $ from 'jquery';

@observer
export class HelpTab extends React.Component {
    componentDidMount() {
        $(document).ready(function () {
            var t = $(".nav-mobile-content .content").height();
            $(".nav-mobile-content").css({
                "min-height": t + 100
            }), $(".nav li").on("click", function () {
                var t = $(this).data("tabs");
                $(".platforma-nav-mobile").removeClass("active"), $(".platforma-nav-mobile-bg").fadeOut(), $(".nav li").removeClass("active"), $(this).addClass("active"), $(".tabs-item").removeClass("active"), $("." + t).addClass("active")
            }), $(".help").on("click", function () {
                var t = $(this).data("help");
                $(".platforma-nav-mobile").removeClass("active"), $(".platforma-nav-mobile-bg").fadeOut(), $(".nav li").removeClass("active"), $(".tabs-item").removeClass("active"), $("." + t).addClass("active")
            }), $(".open-nav").on("click", function () {
                $(".platforma-nav-mobile").addClass("active"), $(".platforma-nav-mobile-bg").fadeIn()
            }), $(".close-nav").on("click", function () {
                $(".platforma-nav-mobile").removeClass("active"), $(".platforma-nav-mobile-bg").fadeOut()
            });
            t = $(window).height();
            1320 <= $(window).width() ? (e = $(".header").outerHeight() + 60, $(".platforma-nav").css({
                height: t - e
            }), $(".content-tabs").css({
                height: t - e
            })) : ($(".platforma-nav").css({
                height: "auto"
            }), $(".content-tabs").css({
                height: "auto"
            })), 1599 <= $(window).width() && (e = $(".header").outerHeight() + 120, $(".platforma-nav").css({
                height: t - e
            }), $(".content-tabs").css({
                height: t - e
            })), $(".program-item").on("click", function () {
                $(this).toggleClass("active")
            }), $(".replenish_js").on("click", function () {
                $(".popup-refill").fadeIn(300)
            }), $(".withdraw_js").on("click", function () {
                $(".popup-withdraw").fadeIn(300)
            }), $(".output_js").on("click", function () {
                $(".popup-output").fadeIn(300)
            }), $(".transaction_js").on("click", function () {
                $(".popup-transaction").fadeIn(300)
            }), $(".cancel-link").on("click", function () {
                $(".popup-transaction").fadeOut(300)
            }), $(".withdraw_active_js").on("click", function () {
                $(".popup-withdraw-active").fadeIn(300)
            }), $(".cancel-link").on("click", function () {
                $(".popup-withdraw-active").fadeOut(300)
            }), $(".close").on("click", function () {
                $(".popup-refill").fadeOut(300), $(".popup-withdraw").fadeOut(300), $(".popup-output").fadeOut(300), $(".popup-successfully").fadeOut(300), $(".popup-transaction").fadeOut(300), $(".popup-withdraw-active").fadeOut(300), $(".popup-replenishment").fadeOut(300), $(".popup-withdraw-pool").fadeOut(300), $(".popup-replenishment-pool").fadeOut(300)
            }), $(".close-popup").on("click", function () {
                $(".popup-refill").fadeOut(300), $(".popup-withdraw").fadeOut(300), $(".popup-output").fadeOut(300), $(".popup-successfully").fadeOut(300), $(".popup-transaction").fadeOut(300), $(".popup-withdraw-active").fadeOut(300), $(".popup-replenishment").fadeOut(300), $(".popup-withdraw-pool").fadeOut(300), $(".popup-replenishment-pool").fadeOut(300)
            }), $(".cancellation-link").on("click", function () {
                $(".popup-output").fadeOut(300)
            }), $(".commission-link li").on("click", function () {
                $(".commission-link li").removeClass("active"), $(this).addClass("active")
            }), $(".monutes-title li").on("click", function () {
                $(".monutes-title li").removeClass("active"), $(this).addClass("active")
            }), $(".replenishment-content .days li").on("click", function () {
                $(".replenishment-content .days li").removeClass("active"), $(this).addClass("active")
            }), $(".help-items .video").on("click", function () {
                $(this).find("iframe").fadeIn()
            });
            var e = $(".help-items .video").width();
            $(".help-items .video").height(e / 1.72), 1024 <= $(window).width() && (e = $(".help-items .video").width(), $(".help-items .video").height(e / 1.78))
        }), $(window).resize(function () {
            var t = $(".help-items .video").width();
            $(".help-items .video").height(t / 1.72), 1024 <= $(window).width() && (t = $(".help-items .video").width(), $(".help-items .video").height(t / 1.78));
            t = $(".nav-mobile-content .content").height();
            $(".nav-mobile-content").css({
                "min-height": t + 100
            });
            var e, t = $(window).height();
            1320 <= $(window).width() ? (e = $(".header").outerHeight() + 60, $(".platforma-nav").css({
                height: t - e
            }), $(".content-tabs").css({
                height: t - e
            })) : ($(".platforma-nav").css({
                height: "auto"
            }), $(".content-tabs").css({
                height: "auto"
            })), 1599 <= $(window).width() && (e = $(".header").outerHeight() + 120, $(".platforma-nav").css({
                height: t - e
            }), $(".content-tabs").css({
                height: t - e
            })), 1320 <= $(window).width() && $(".content-tabs").mCustomScrollbar()
        }), $(window).on("load", function () {
            1320 <= $(window).width() && $(".content-tabs").mCustomScrollbar(), $(".drop-content").mCustomScrollbar()
        });
    }
    render() {
        return (
            <div className="tabs-item help-content active">
                <div className="help-items">
                    <div className="help-item">
                        <div className="video"  id='help_video1'>
                            <iframe src="https://www.youtube.com/embed/djr5ej_HYY4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            <div className="text">
                                <h3>About Unichain platform</h3>
                                <span><i className="icon-play"></i> Watch</span>
                            </div>
                        </div>
                        <div className="download">
                            <a target="_blank" download="presentation" href="https://platforms.unichaincoin.com/uploads/eng.pdf"><i className="icon-bottom-arrow"></i>Download presentation</a>
                            <p>To learn theme better download our presentation</p>
                        </div>
                    </div>
                    <div className="help-item">
                        <div className="video"  id='help_video2'>
                            <iframe src="https://www.youtube.com/embed/nozPGhDbG-o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            <div className="text">
                                <h3>關於 Unichain 平台 </h3>
                                <span><i className="icon-play"></i> 手錶</span>
                            </div>
                        </div>
                        <div className="download">
                            <a target="_blank" download="推介會" href="https://platforms.unichaincoin.com/uploads/kt.pdf"><i className="icon-bottom-arrow"></i>下載演示文稿 </a>
                            <p>要更好地學習主題，請下載我們的演示文稿 </p>
                        </div>
                    </div>
                </div>
                <div className="info-content">
                    <p><i className="icon-info2"></i> How to connect MetaMask  <img id='info' src="https://platforms.unichaincoin.com/uploads/help-info.svg" alt=""/>to Unichain platform ?</p>
                    <a target="_blank" href="https://youtu.be/GNPz-Dv5BjM"><i className="icon-play"></i> Watch video </a>
                </div>
            </div>
        )
    }
}