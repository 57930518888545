import React from "react";
import classNames from "classnames";
import { LiquidityTransactionConfirmModal } from "../../modals/LiquidityTransactionConfirmModal";
import { EthWithdrawModalAddress } from "../../modals/EthWithdrawModalAddress";
import { EthWithdrawModal } from "../../modals/EthWithdrawModal";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { AuthStore, ModalStore, WalletStore } from "../../stores";
import { Modals } from "../../stores/ModalStore";
import { pd, processRequestError } from "../../utils/utilities";
import { toast } from "react-toastify";

interface ILiquidityTileProps {
  icon: string;
  valute: string;
  title: string;
  coinId: "UFP" | "ETH";
  infoWindow: string;
  // symbol: string;
  prematureWithdrawTime: string;
  prematureWithdrawCommission: string;
  periods?: { months: string; present?: { title: string; text: string } }[];
  riskLine: string;
  valute_name: string;
  total_payed_unch: number;
  card_style: { background: string };
  disabled?: boolean;
  info_text: string;
  info_title: string;
  usdt_balance: number;
  minimum_amount: number;
  showis_withdraw?: boolean;
}

interface ILiquidityTileState {
  amount: number;
  days: number;
  showConfirmModal: boolean;
  loading: boolean;
  showis_withdraw: boolean;
  showWithdrawModal: boolean;
}
function UsdtCompareException(message) {
  this.message = message;
  this.name = "Exception to coompare balance amount";
}
@observer
export class LiquidityTile extends React.Component<
  ILiquidityTileProps,
  ILiquidityTileState
> {
  @resolve(AuthStore)
  protected declare readonly authStore: AuthStore;
  @resolve(ModalStore)
  protected declare readonly modalStore: ModalStore;
  @resolve(WalletStore)
  private declare readonly walletStore: WalletStore;

  state: ILiquidityTileState = {
    amount: 0,
    days: 30,
    showConfirmModal: false,
    loading: false,
    showis_withdraw: false,
    showWithdrawModal: false,
  };

  // componentDidMount() {
  // 	this.setState({ days: this.props.periods[0].days });
  // }

  onConfirm = async () => {
    this.setState({ loading: true, showConfirmModal: false });
    try {
      if (this.authStore.profile?.usdt_balance < this.state.amount)
        throw new UsdtCompareException("Usdt amount must be bigger");
      await this.authStore.liquidityDeposit(
        this.state.amount,
        this.authStore.profile?.username,
        this.props.coinId.toLowerCase(),
        this.state.days
      );
      toast.success("Liquidity deposit successful");
    } catch (e) {
      processRequestError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { amount, days, showConfirmModal, showWithdrawModal, loading } =
      this.state;

    const {
      icon,
      coinId,
      title,
      infoWindow,
      usdt_balance,
      valute,
      prematureWithdrawTime,
      prematureWithdrawCommission,
      periods,
      riskLine,
      valute_name,
      card_style,
      disabled,
      info_text,
      info_title,
      showis_withdraw,
      minimum_amount,
    } = this.props;

    return (
      <div className={`pool-item ${disabled && "disabled"}`} style={card_style}>
        <img className="pool-after" src={icon} alt="" />
        <div className="pool-content">
          <p>
            {valute_name}
            <span className="info-tooltip">
              <i className="icon-info" />
              {/* <i className="icon-info"/> */}
              <span className="info-window">{infoWindow}</span>
            </span>
          </p>

          <div className="balance">
            <span>Total balance</span>
            <h3>
              <img src={valute} />
              {usdt_balance}
            </h3>
            {coinId == "UFP" && (
              <>
                <i style={{ color: "rgb(144, 238, 144)", opacity: 1 }}>
                  = $
                  {(
                    this.authStore.profile?.settings.unichain_price *
                    this.authStore.profile?.total_payed_unch
                  ).toFixed(2)}
                </i>
                <br />
              </>
            )}
            <i>{valute_name}</i>
          </div>
          <ul>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => this.setState({ showConfirmModal: true })}
              >
                <i className="icon-plus"></i>Deposit
              </a>
            </li>
            {showis_withdraw === true && (
              <li>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.setState({ showWithdrawModal: true })}
                >
                  <i className="icon-top-arrow"></i>
                  Withdraw
                </a>
              </li>
            )}
          </ul>
        </div>
        <EthWithdrawModalAddress
          show={showWithdrawModal}
          onHide={() => this.setState({ showWithdrawModal: false })}
          onConfirm={this.onConfirm}
          minimum_amount={this.props.minimum_amount}
        />
        <LiquidityTransactionConfirmModal
          amount={
            this.props.coinId.toLowerCase() == "ufp"
              ? this.walletStore.unichainBalance?.toFixed(2)
              : this.authStore.profile?.ethereum_balance
          }
          periods={periods}
          coin={this.props.coinId.toLowerCase()}
          show={showConfirmModal}
          onHide={() => this.setState({ showConfirmModal: false })}
          onConfirm={this.onConfirm}
          minimum_amount={this.props.minimum_amount}
        />
      </div>
    );
  }
}
