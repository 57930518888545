import React from "react";

type gameLevelType = {
  active: boolean;
  money_amount: string;
  tooltip_text: string;
  help_text: string;
};

function GameLevel(props: gameLevelType) {
  return (
    <li className={props.active ? "active" : ''}>
      {!props.active && (<i className="icon-roket"></i>)}
      <span>{props.money_amount}</span>
      <div className="tooltip">
        <h4>{props.tooltip_text}</h4>
        <p>{props.help_text}</p>
      </div>
    </li>
  );
}
export default GameLevel;
